import './App.css';
// @ts-ignore
import SearchPage from "./pages/SearchPage/SearchPage.tsx";
// @ts-ignore
import DocsPage from "./pages/DocsPage/DocsPage.tsx";
// @ts-ignore
import Navigation from "./components/Navigation/Navigation.tsx";
// @ts-ignore
import Item from "./components/Navigation/Item.tsx";
// @ts-ignore
import Footer from "./components/Footer/Footer.tsx";
// @ts-ignore
import React, { useState } from 'react';
// @ts-ignore
import Content from "./components/Content/Content.tsx";

export default function App() {
    const [route, setRoute] = useState(<SearchPage />);

    return (
        <>
            <Navigation>
                <Item name="Главная" onClick={() => setRoute(<SearchPage />)}/>
                <Item name="Документация" onClick={() => setRoute(<DocsPage />)}/>
            </Navigation>
            <Content>
                {route}
            </Content>
            <Footer/>
        </>
    );
}
