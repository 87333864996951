// @ts-ignore
import Input from "../../components/Input/Input.tsx";
// @ts-ignore
import Header from "../../components/Header/Header.tsx";
// @ts-ignore
import Card from "../../components/Card/Card.tsx";
// @ts-ignore
import Button from "../../components/Button/Button.tsx";
// @ts-ignore
import React, {useState} from "react";
// @ts-ignore
import {validate} from "../../api/validate.ts";
// @ts-ignore
import ValidateEmail from "../../components/reports/ValidateEmail.tsx";

export default function SearchPage() {
    const [email, setEmail] = useState('');
    const [validateResults, setValidateResults] = useState([]);

    const validateResultsFetch = async () => {
        try {
            const result = await validate(email);
            setValidateResults(result);
        } catch (error) {
            console.error("Ошибка загрузки инструкции:", error);
        }
    };

    return (
        <>
            <Header name="Почтовая Валидация">
                Сервис "Почтовая Валидация" предназначен для проверки и подтверждения корректности
                электронных адресов. Он помогает бизнесам и организациям обеспечить высокое качество
                своей базы данных клиентов, минимизируя количество недействительных или ошибочных адресов.
            </Header>
            <Card>
                <Input type='email' name='Почтовый адрес' value={email} onChange={(e) => {
                    setEmail(e.target.value);
                }}></Input>
                <Button onClick={validateResultsFetch}>Проверить</Button>
                <ValidateEmail validate={validateResults} />
            </Card>
        </>
    );
}
